








import CopyStakeStreamerSettingsForm from '@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamerSettingsForm.vue';
import CopyStakeStreamerDisclaimer from '@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamerDisclaimer.vue';
import CopyStakeStreamersTable from '@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamersTable.vue';
import FeatureFlagRedirectMixin from '@/mixins/copy-stake/FeatureFlagRedirectMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'CopyStakeStreamersView',
  mixins: [FeatureFlagRedirectMixin],
  components: {
    CopyStakeStreamersTable,
    CopyStakeStreamerSettingsForm,
    CopyStakeStreamerDisclaimer
  },
  computed: {
    ...mapGetters('Onboarding', ['isCopyStakeGeneralIntegrationMode']),
    isShowSettingsForm(): boolean {
      return this.isSuperAdmin || this.isCopyStakeGeneralIntegrationMode;
    }
  }
};
